import { FunctionComponent, useState, useEffect } from 'react';
import { Typography, Modal, Col, Row } from 'antd';
import { ModalProps } from 'antd/lib/modal';

export interface SizedModalProps extends ModalProps {
	titleBackgroundColor?: string;
	size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | string | undefined;
	justify?: 'start' | 'end' | 'center' | 'space-around' | 'space-between' | undefined;
	resizeToContent?: boolean;
}

const { Text } = Typography;

const SizedModal: FunctionComponent<SizedModalProps> = ({ size, titleBackgroundColor, resizeToContent, justify, children, ...props }) => {
	const [width, setWidth] = useState<number>(0);
	const [height, setHeight] = useState<number>(0);

	var proportion: number = 0;
	switch (size) {
		case 'xsmall':
			proportion = 1 / 5;
			break;
		case 'small':
			proportion = 1 / 3;
			break;
		case 'medium':
			proportion = 1 / 2;
			break;
		case 'large':
			proportion = 3 / 4;
			break;
		case 'xlarge':
			proportion = 4 / 5;
			break;
		default:
			if (size.charAt(2) === '%') proportion = parseInt(size.substring(0, 2)) / 100;
			else proportion = 1;
	}

	useEffect(() => {
		setWidth(window.innerWidth * proportion);
		setHeight(window.innerHeight * proportion);
	}, [proportion]);

	return (
		<Modal
			title={
				<Col>
					<Row justify={justify}>
						<Text strong>{props.title}</Text>
					</Row>
				</Col>
			}
			style={{ height: height }}
			forceRender
			getContainer={false}
			width={width}
			{...props}
		>
			<div>{children}</div>
		</Modal>
	);
};

export default SizedModal;
