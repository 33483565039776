import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import './web.config';
import './assets/locale';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { StrictMode } from 'react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<StrictMode>
		<App />
	</StrictMode>,
);
reportWebVitals();
serviceWorker.unregister();
