import { Space, Typography, Tag } from 'antd';
import { ClockCircleOutlined, LaptopOutlined } from '@ant-design/icons';
import { timeFormat, colors } from '../../assets';
import dayjs from 'dayjs';
import IconClinic from '../../assets/svg/icon-clinic';
import IconStethoscope from '../../assets/svg/icon-stethoscope';

const { Text } = Typography;

const NodeEncounter = ({ guid, date, professional_name, professional_specialty, system, scope, type, location }) => {
	switch (scope) {
		case 'Quirúrgico':
			return (
				<Space key={'space-1-' + guid} direction="vertical">
					<Space key={'space-2-' + guid} direction="horizontal" wrap>
						<ClockCircleOutlined key={'icon-1-' + guid} />
						<Text key={'text-1-' + guid} style={{ width: 100 }}>
							{dayjs(date).format(timeFormat)}
						</Text>
						<Text key={'text-2-' + guid}>{professional_name}</Text>
						{professional_specialty ? (
							<Tag key={'tag-1-' + guid} color={colors.brandColor2} icon={<IconStethoscope />}>
								{professional_specialty}
							</Tag>
						) : null}
					</Space>
					<Space key={'space-3-' + guid} direction="horizontal" size={1} wrap>
						<Tag key={'tag-2-' + guid} color={colors.brandColor1} icon={<LaptopOutlined />}>
							{system}
						</Tag>
						<Tag key={'tag-3-' + guid} color={colors.brandColor1}>
							{scope}
						</Tag>
						<Tag key={'tag-4-' + guid} color={colors.brandColor1}>
							{type}
						</Tag>
						<Tag key={'tag-5-' + guid} color={colors.brandColor1} icon={<IconClinic />}>
							{location}
						</Tag>
					</Space>
				</Space>
			);
		case 'Hospitalario':
			return (
				<Space key={'space-1-' + guid} direction="vertical">
					<Space key={'space-2-' + guid} direction="horizontal" wrap>
						<ClockCircleOutlined key={'icon-1-' + guid} />
						<Text key={'text-1-' + guid} style={{ width: 100 }}>
							{dayjs(date).format(timeFormat)}
						</Text>
						<Text key={'text-2-' + guid}>{professional_name}</Text>
						{professional_specialty ? (
							<Tag key={'tag-1-' + guid} color={colors.brandColor2} icon={<IconStethoscope />}>
								{professional_specialty}
							</Tag>
						) : null}
					</Space>
					<Space key={'space-3-' + guid} direction="horizontal" size={1} wrap>
						<Tag key={'tag-2-' + guid} color={colors.brandColor1} icon={<LaptopOutlined />}>
							{system}
						</Tag>
						<Tag key={'tag-3-' + guid} color={colors.brandColor1}>
							{scope}
						</Tag>
						<Tag key={'tag-4-' + guid} color={colors.brandColor1}>
							{type}
						</Tag>
						<Tag key={'tag-5-' + guid} color={colors.brandColor1} icon={<IconClinic />}>
							{location}
						</Tag>
					</Space>
				</Space>
			);
		case 'Ambulatorio':
			return (
				<Space key={'space-1-' + guid} direction="vertical">
					<Space key={'space-2-' + guid} direction="horizontal" wrap>
						<ClockCircleOutlined key={'icon-1-' + guid} />
						<Text key={'text-1-' + guid} style={{ width: 100 }}>
							{dayjs(date).format(timeFormat)}
						</Text>
						<Text key={'text-2-' + guid}>{professional_name}</Text>
						{professional_specialty ? (
							<Tag key={'tag-1-' + guid} color={colors.brandColor2} icon={<IconStethoscope />}>
								{professional_specialty}
							</Tag>
						) : null}
					</Space>
					<Space key={'space-3-' + guid} direction="horizontal" size={1} wrap>
						<Tag key={'tag-2-' + guid} color={colors.brandColor1} icon={<LaptopOutlined />}>
							{system}
						</Tag>
						<Tag key={'tag-3-' + guid} color={colors.brandColor1}>
							{scope}
						</Tag>
						<Tag key={'tag-4-' + guid} color={colors.brandColor1}>
							{type}
						</Tag>
						<Tag key={'tag-5-' + guid} color={colors.brandColor1} icon={<IconClinic />}>
							{location}
						</Tag>
					</Space>
				</Space>
			);
	}
};

export default NodeEncounter;
