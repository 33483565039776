import { Row, Space, Typography, Divider, Descriptions, Input, Tag, DescriptionsProps } from 'antd';
import { colors } from '../../assets/styles';
import { dateTimeFormat } from '../../assets/formats';
import { useTranslation } from 'react-i18next';
import { LaptopOutlined } from '@ant-design/icons';
import { useAppConfigContext } from '../../utils/useAppConfigContext';
import dayjs from 'dayjs';

const { Text, Title } = Typography;
const { TextArea } = Input;

const NodeNote = ({ encounter, guid }) => {
	const { t } = useTranslation();
	const { isDarkMode } = useAppConfigContext();
	const encounter_notes = encounter.registros ? encounter.registros : [];
	const encounter_date = encounter.fecha ? encounter.fecha : '';
	const encounter_place = encounter.establecimiento ? encounter.establecimiento.descripcion : '';
	const encounter_system = encounter.sistema;
	const encounter_scope = encounter.ambito;
	const encounter_professional = encounter.profesional
		? encounter.profesional
		: {
				nombre_completo: '',
				especialidad: '',
		  };
	var items: DescriptionsProps['items'] = [];
	var allNotes = [];

	switch (encounter.tipo) {
		case 'Epicrisis':
			const ingress_diagnosis = encounter.diagnostico_ingreso ? encounter.diagnostico_ingreso : '';
			const discharge_diagnosis = encounter.diagnostico_egreso ? encounter.diagnostico_egreso : '';
			const discharge_condition = encounter.condicion_egreso ? encounter.condicion_egreso : '';
			const days_hospitalized = encounter.dias_hospitalizado ? encounter.dias_hospitalizado : '';
			const discharge_date = encounter.fecha_egreso ? encounter.fecha_egreso : '';
			const admission_date = encounter.fecha_ingreso ? encounter.fecha_ingreso : '';
			const discharge_instructions = encounter.indicaciones_alta ? encounter.indicaciones_alta : '';
			const surgical_procedures = encounter.procedimientos_cirugia ? encounter.procedimientos_cirugia : '';
			const next_appointment = encounter.proximo_control ? encounter.proximo_control : '';
			const next_appointment_professional = encounter.proximo_control_profesional ? encounter.proximo_control_profesional : '';
			const complications_during_stay = encounter.complicaciones_estadia ? encounter.complicaciones_estadia : '';
			items.push({
				key: encounter.guid + '-ep01',
				label: t('management.admission_date'),
				children: (
					<Text key={'text-' + encounter.guid + '-ep01'} style={{ margin: 0, paddingBottom: 4 }}>
						{dayjs(admission_date).format(dateTimeFormat)}
					</Text>
				),
			});
			items.push({
				key: encounter.guid + '-ep02',
				label: t('management.ingress_diagnose'),
				children: (
					<Text key={'text-' + encounter.guid + '-ep02'} style={{ margin: 0, paddingBottom: 4 }}>
						{ingress_diagnosis}
					</Text>
				),
			});
			items.push({
				key: encounter.guid + '-ep03',
				label: t('management.surgical_procedures'),
				children: (
					<Text key={'text-' + encounter.guid + '-ep03'} style={{ margin: 0, paddingBottom: 4 }}>
						{surgical_procedures}
					</Text>
				),
			});
			items.push({
				key: encounter.guid + '-ep04',
				label: t('management.complications_during_stay'),
				children: (
					<Text key={'text-' + encounter.guid + '-ep04'} style={{ margin: 0, paddingBottom: 4 }}>
						{complications_during_stay}
					</Text>
				),
			});
			items.push({
				key: encounter.guid + '-ep05',
				label: t('management.discharge_diagnose'),
				children: (
					<Text key={'text-' + encounter.guid + '-ep05'} style={{ margin: 0, paddingBottom: 4 }}>
						{discharge_diagnosis}
					</Text>
				),
			});
			items.push({
				key: encounter.guid + '-ep06',
				label: t('management.discharge_date'),
				children: (
					<Text key={'text-' + encounter.guid + '-ep06'} style={{ margin: 0, paddingBottom: 4 }}>
						{dayjs(discharge_date).format(dateTimeFormat)}
					</Text>
				),
			});
			items.push({
				key: encounter.guid + '-ep07',
				label: t('management.days_hospitalized'),
				children: (
					<Text key={'text-' + encounter.guid + '-ep07'} style={{ margin: 0, paddingBottom: 4 }}>
						{days_hospitalized}
					</Text>
				),
			});
			items.push({
				key: encounter.guid + '-ep08',
				label: t('management.discharge_instructions'),
				children: (
					<TextArea
						key={encounter.guid + '-08ta'}
						style={{ color: colors.backgroundColor, backgroundColor: 'transparent', borderColor: 'transparent' }}
						autoSize={true}
						value={discharge_instructions}
						disabled
					/>
				),
			});
			items.push({
				key: encounter.guid + '-ep09',
				label: t('management.discharge_condition'),
				children: (
					<Text key={'text-' + encounter.guid + '-ep09'} style={{ margin: 0, paddingBottom: 4 }}>
						{discharge_condition}
					</Text>
				),
			});
			items.push({
				key: encounter.guid + '-ep10',
				label: t('management.next_appointment'),
				children: (
					<Text key={'text-' + encounter.guid + '-ep10'} style={{ margin: 0, paddingBottom: 4 }}>
						{next_appointment ? dayjs(next_appointment).format(dateTimeFormat) : ''}
					</Text>
				),
			});
			items.push({
				key: encounter.guid + '-ep11',
				label: t('management.next_appointment_professional'),
				children: (
					<Text key={'text-' + encounter.guid + '-ep11'} style={{ margin: 0, paddingBottom: 4 }}>
						{next_appointment_professional}
					</Text>
				),
			});
			items.push({
				key: encounter.guid + '-ep12',
				label: t('management.notes'),
				children: (
					<TextArea
						key={encounter.guid + '-12ta'}
						style={{ color: colors.backgroundColor, backgroundColor: 'transparent', borderColor: 'transparent' }}
						value={encounter_notes}
						autoSize={true}
						disabled
					/>
				),
			});
			items.push({
				key: encounter.guid + '-ep13',
				label: t('management.professional'),
				children: (
					<Title key={'title-' + encounter.guid + '-ep13'} level={5} style={{ margin: 0, paddingBottom: 4 }}>
						{encounter_professional.nombre_completo + ' - ' + encounter_professional.especialidad}
					</Title>
				),
			});
			return (
				<div key={'div-description-' + encounter.guid}>
					<Descriptions
						key={'description-' + encounter.guid}
						bordered
						labelStyle={{ width: 200 }}
						column={1}
						title={
							<Space key={'space-epi-' + encounter.guid} direction="horizontal">
								<Title key={'title-' + encounter.guid} level={5} style={{ margin: 0, paddingBottom: 4 }}>
									{dayjs(encounter_date).format(dateTimeFormat) + ' - ' + encounter_place}
								</Title>
								<Tag key={'tag-' + encounter.guid + '-01'} color={colors.brandColor1} icon={<LaptopOutlined key={'icon-' + encounter.guid + '-01'} />}>
									{encounter_system}
								</Tag>
								<Tag key={'tag-' + encounter.guid + '-02'} color={colors.brandColor1} icon={<LaptopOutlined key={'icon-' + encounter.guid + '-02'} />}>
									{encounter_scope}
								</Tag>
							</Space>
						}
						items={items}
					/>
					<Divider key={'divider-' + encounter.guid + '-epi'} style={{ borderColor: colors.brandColor1 }} />
				</div>
			);
		case 'Protocolo operatorio':
			const preoperative_diagnosis = encounter.diagnostico_preoperatorio ? encounter.diagnostico_preoperatorio : '';
			const postoperative_diagnosis = encounter.diagnostico_postoperatorio ? encounter.diagnostico_postoperatorio : '';
			const biopsy = encounter.biopsia ? encounter.biopsia : '';
			const code = encounter.codigo ? encounter.codigo : '';
			const endDate_anesthesia = encounter.fecha_fin_anestesia ? encounter.fecha_fin_anestesia : '';
			const startDate_anesthesia = encounter.fecha_inicio_anestesia ? encounter.fecha_inicio_anestesia : '';
			const date_surgery = encounter.fecha_operacion ? encounter.fecha_operacion : '';
			const start_time = encounter.hora_inicio ? encounter.hora_inicio : '';
			const end_time = encounter.hora_termino ? encounter.hora_termino : '';
			const operating_room_name = encounter.nombre_pabellon ? encounter.nombre_pabellon : '';
			const emergency_operating_room = encounter.pabellon_urgencia ? encounter.pabellon_urgencia : '';
			allNotes = [];
			encounter_notes.forEach(r => {
				if (r) {
					var temp = r.split('\n');
					temp.forEach(e => {
						allNotes.push(e);
					});
				}
			});
			items.push({
				key: encounter.guid + '-po01',
				label: t('management.date_surgery'),
				children: (
					<Text key={'text-' + encounter.guid + '-po01'} style={{ margin: 0, paddingBottom: 4 }}>
						{dayjs(date_surgery).format(dateTimeFormat)}
					</Text>
				),
			});
			items.push({
				key: encounter.guid + '-po02',
				label: t('management.preoperative_diagnosis'),
				children: (
					<Text key={'text-' + encounter.guid + '-po02'} style={{ margin: 0, paddingBottom: 4 }}>
						{preoperative_diagnosis}
					</Text>
				),
			});
			items.push({
				key: encounter.guid + '-po03',
				label: t('management.startDate_anesthesia'),
				children: (
					<Text key={'text-' + encounter.guid + '-po03'} style={{ margin: 0, paddingBottom: 4 }}>
						{dayjs(startDate_anesthesia).format(dateTimeFormat)}
					</Text>
				),
			});
			items.push({
				key: encounter.guid + '-po04',
				label: t('management.start_time'),
				children: (
					<Text key={'text-' + encounter.guid + '-po04'} style={{ margin: 0, paddingBottom: 4 }}>
						{dayjs(start_time).format(dateTimeFormat)}
					</Text>
				),
			});
			items.push({
				key: encounter.guid + '-po05',
				label: t('management.end_time'),
				children: (
					<Text key={'text-' + encounter.guid + '-po05'} style={{ margin: 0, paddingBottom: 4 }}>
						{dayjs(end_time).format(dateTimeFormat)}
					</Text>
				),
			});
			items.push({
				key: encounter.guid + '-po06',
				label: t('management.endDate_anesthesia'),
				children: (
					<Text key={'text-' + encounter.guid + '-po06'} style={{ margin: 0, paddingBottom: 4 }}>
						{dayjs(endDate_anesthesia).format(dateTimeFormat)}
					</Text>
				),
			});
			items.push({
				key: encounter.guid + '-po07',
				label: t('management.code'),
				children: (
					<Text key={'text-' + encounter.guid + '-po07'} style={{ margin: 0, paddingBottom: 4 }}>
						{code}
					</Text>
				),
			});
			items.push({
				key: encounter.guid + '-po08',
				label: t('management.postoperative_diagnosis'),
				children: (
					<Text key={'text-' + encounter.guid + '-po08'} style={{ margin: 0, paddingBottom: 4 }}>
						{postoperative_diagnosis}
					</Text>
				),
			});
			items.push({
				key: encounter.guid + '-po09',
				label: t('management.biopsy'),
				children: (
					<Text key={'text-' + encounter.guid + '-po09'} style={{ margin: 0, paddingBottom: 4 }}>
						{biopsy}
					</Text>
				),
			});
			items.push({
				key: encounter.guid + '-po10',
				label: t('management.operating_room_name'),
				children: (
					<Text key={'text-' + encounter.guid + '-po10'} style={{ margin: 0, paddingBottom: 4 }}>
						{operating_room_name}
					</Text>
				),
			});
			items.push({
				key: encounter.guid + '-po11',
				label: t('management.emergency_operating_room'),
				children: (
					<Text key={'text-' + encounter.guid + '-po11'} style={{ margin: 0, paddingBottom: 4 }}>
						{emergency_operating_room}
					</Text>
				),
			});
			items.push({
				key: encounter.guid + '-po12',
				label: t('management.notes'),
				children: (
					<TextArea
						key={encounter.guid + '-12ta'}
						style={{ color: colors.backgroundColor, backgroundColor: 'transparent', borderColor: 'transparent' }}
						value={encounter_notes}
						autoSize={true}
						disabled
					/>
				),
			});
			items.push({
				key: encounter.guid + '-po13',
				label: t('management.professional'),
				children: (
					<Title key={'title-' + encounter.guid + '-po13'} level={5} style={{ margin: 0, paddingBottom: 4 }}>
						{encounter_professional.nombre_completo + ' - ' + encounter_professional.especialidad}
					</Title>
				),
			});
			return (
				<div key={'div-description-' + encounter.guid}>
					<Descriptions
						key={'description-' + encounter.guid}
						bordered
						labelStyle={{ width: 200 }}
						column={1}
						title={
							<Space key={'space-' + encounter.guid + '-po'} direction="horizontal">
								<Title key={'title-description-' + encounter.guid} id={guid} level={5} style={{ margin: 0, paddingBottom: 4 }}>
									{dayjs(encounter_date).format(dateTimeFormat) + ' - ' + encounter_place}
								</Title>
								<Tag key={'tag-' + encounter.guid + '-01'} color={colors.brandColor1} icon={<LaptopOutlined key={'icon-' + encounter.guid + '-01'} />}>
									{encounter_system}
								</Tag>
								<Tag key={'tag-' + encounter.guid + '-02'} color={colors.brandColor1} icon={<LaptopOutlined key={'icon-' + encounter.guid + '-02'} />}>
									{encounter_scope}
								</Tag>
							</Space>
						}
						items={items}
					/>
					<Divider key={'divider-' + encounter.guid + '-po'} style={{ borderColor: colors.brandColor1 }} />
				</div>
			);
		case 'Consulta urgencia':
			var encounter_diagnoses = encounter.diagnosticos ? encounter.diagnosticos : [];
			var encounter_indications = encounter.indicaciones ? encounter.indicaciones : [];
			var encounter_prescriptions = encounter.prescripciones ? encounter.prescripciones : [];
			encounter_notes.forEach(r => {
				if (r.registro) {
					var temp = r.registro.split('\n');
					temp.forEach(e => {
						allNotes.push(e);
					});
				}
			});
			items = [];
			if (allNotes.length > 0)
				items.push({
					key: encounter.guid + '-cu01',
					label: t('management.notes'),
					children: encounter_notes.map((item, index) => (
						<Row key={encounter.guid + '-' + index + '-01row'}>
							<TextArea
								key={encounter.guid + '-' + index + '-01ta'}
								style={{ color: colors.backgroundColor, backgroundColor: 'transparent', borderColor: 'transparent' }}
								value={item.registro}
								autoSize={true}
								disabled
							/>
						</Row>
					)),
				});
			if (encounter.diagnosticos?.length > 0)
				items.push({
					key: encounter.guid + '-cu02',
					label: t('management.diagnoses'),
					children: (
						<Space key={'space-diagnoses-' + encounter.guid} direction="vertical">
							{[encounter_diagnoses?.map((item, index) => <Text key={'diagnoses-' + encounter.guid + '-' + index}>- {item.registro}</Text>)]}
						</Space>
					),
				});
			if (encounter_indications.length > 0 || encounter_prescriptions.length > 0)
				items.push({
					key: encounter.guid + '-cu03',
					label: t('management.indications'),
					children: (
						<Space key={'space-indications-' + encounter.guid} direction="vertical">
							{[
								encounter_indications.map((item, index) => <Text key={'indications-' + encounter.guid + '-' + index}>- {item.registro}</Text>),
								encounter_prescriptions.map((item, index) => <Text key={'prescriptions-' + encounter.guid + '-' + index}>- {item.registro}</Text>),
							]}
						</Space>
					),
				});
			items.push({
				key: encounter.guid + '-cu04',
				label: t('management.professional'),
				children: (
					<Title key={'title-' + encounter.guid + '-cu'} level={5} style={{ margin: 0, paddingBottom: 4 }}>
						{encounter_professional.nombre_completo + ' - ' + encounter_professional.especialidad}
					</Title>
				),
			});
			return (
				<div key={'div-description-' + encounter.guid}>
					<Descriptions
						key={'description-' + encounter.guid}
						bordered
						labelStyle={{ width: 200 }}
						column={1}
						title={
							<Space key={'space-' + encounter.guid + '-cu'} direction="horizontal">
								<Title key={'title-' + encounter.guid + '-cu'} level={5} style={{ margin: 0, paddingBottom: 4 }}>
									{dayjs(encounter_date).format(dateTimeFormat) + ' - ' + encounter_place}
								</Title>
								<Tag key={'tag-' + encounter.guid + '-01'} color={colors.brandColor1} icon={<LaptopOutlined key={'icon-' + encounter.guid + '-01'} />}>
									{encounter_system}
								</Tag>
								<Tag key={'space-' + encounter.guid + '-02'} color={colors.brandColor1} icon={<LaptopOutlined key={'icon-' + encounter.guid + '-02'} />}>
									{encounter_scope}
								</Tag>
							</Space>
						}
						items={items}
					/>
					<Divider key={'divider-' + encounter.guid + '-urg'} style={{ borderColor: colors.brandColor1 }} />
				</div>
			);
		case 'Consulta ambulatoria':
			encounter_diagnoses = encounter.diagnosticos ? encounter.diagnosticos : [];
			encounter_indications = encounter.indicaciones ? encounter.indicaciones : [];
			encounter_prescriptions = encounter.prescripciones ? encounter.prescripciones : [];
			encounter_notes.forEach(r => {
				if (r.registro) {
					var temp = r.registro.split('\n');
					temp.forEach(e => {
						allNotes.push(e);
					});
				}
			});
			items = [];
			if (allNotes.length > 0)
				items.push({
					key: encounter.guid + '-ca01',
					label: t('management.notes'),
					children: encounter_notes.map((item, index) => (
						<Row key={encounter.guid + '-' + index + '-01row'}>
							<TextArea
								key={encounter.guid + '-' + index + '-01ta'}
								style={{ color: isDarkMode ? 'white' : 'black', backgroundColor: 'transparent', borderColor: 'transparent' }}
								value={item.registro}
								autoSize={true}
								disabled
							/>
						</Row>
					)),
				});
			if (encounter.diagnosticos?.length > 0)
				items.push({
					key: encounter.guid + '-ca02',
					label: t('management.diagnoses'),
					children: (
						<Space direction="vertical" key={'space-diagnoses-' + encounter.guid}>
							{[encounter_diagnoses?.map((item, index) => <Text key={'diagnoses-' + encounter.guid + '-' + index}>- {item.registro}</Text>)]}
						</Space>
					),
				});
			if (encounter_indications.length > 0 || encounter_prescriptions.length > 0)
				items.push({
					key: encounter.guid + '-ca03',
					label: t('management.indications'),
					children: (
						<Space key={'space-indications-' + encounter.guid} direction="vertical">
							{[
								encounter_indications.map((item, index) => <Text key={'indications-' + encounter.guid + '-' + index}>- {item.registro}</Text>),
								encounter_prescriptions.map((item, index) => <Text key={'prescriptions-' + encounter.guid + '-' + index}>- {item.registro}</Text>),
							]}
						</Space>
					),
				});
			items.push({
				key: encounter.guid + '-ca04',
				label: t('management.professional'),
				children: (
					<Title key={'title-' + encounter.guid} level={5} style={{ margin: 0, paddingBottom: 4 }}>
						{encounter_professional.nombre_completo + ' - ' + encounter_professional.especialidad}
					</Title>
				),
			});
			return (
				<div key={'div-description-' + encounter.guid}>
					<Descriptions
						key={'description-' + encounter.guid}
						bordered
						labelStyle={{ width: 200 }}
						column={1}
						title={
							<Space key={'space-' + encounter.guid} direction="horizontal">
								<Title key={'title-' + encounter.guid + '-ca'} level={5} style={{ margin: 0, paddingBottom: 4 }}>
									{dayjs(encounter_date).format(dateTimeFormat) + ' - ' + encounter_place}
								</Title>
								<Tag key={'tag-' + encounter.guid + '-01'} color={colors.brandColor1} icon={<LaptopOutlined key={'icon-' + encounter.guid + '-01'} />}>
									{encounter_system}
								</Tag>
								<Tag key={'tag-' + encounter.guid + '-02'} color={colors.brandColor1} icon={<LaptopOutlined key={'icon-' + encounter.guid + '-02'} />}>
									{encounter_scope}
								</Tag>
							</Space>
						}
						items={items}
					/>
					<Divider key={'divider-' + encounter.guid + '-ca'} style={{ borderColor: colors.brandColor1 }} />
				</div>
			);
	}
};

export default NodeNote;
